angular.module('fingerink')
    .directive('registerPlan', function () {
        return {
            restrict: 'E',
            replace: 'true',
            templateUrl: 'web/public/register/directives/plan/plan.tpl.html',
            scope: {
                register: '=',
                parent: '='

            },
            controllerAs: 'controller',
            controller: 'registerPlanCtrl'
        };
    })
    .controller('registerPlanCtrl', function ($scope) {
        var that = this;

        that.parent = $scope.parent;
        that.register = $scope.register;

        that.user = $scope.register.user;

        that.siguiente = function () {
            that.error = false;
        };


        that.coin = that.parent.paisesMap[that.user.company.country.id].coin;
        that.pais = that.parent.paisesMap[that.user.company.country.id];
        that.productosMap = that.parent.productosMap;
        that.precios = that.parent.precios;
     
    });
